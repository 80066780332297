// src/App.js
import React from "react";
import Navbar from "./components/Navbar";
import ImageCarousel from "./components/ImageCarousel";
import "./App.css";
import Footer from "./components/Footer";
import CompanyLogos from "./components/CompanyLogos";
import PopularProducts from "./components/PopularProducts";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="content-wrapper">
        <div className="content">
          <ImageCarousel />
          <div className="centered-image-container">
            <img
              src="/images/centered-image.jpg"
              alt="Centered"
              className="centered-image"
            />
          </div>
          <h2 className="section-heading">New Launches</h2>

          <div className="image-slider">
            <div className="image-slider-track">
              <img
                src="/images/grid-image1.png"
                alt="Grid 1"
                className="slider-image"
              />
              <img
                src="/images/grid-image2.png"
                alt="Grid 2"
                className="slider-image"
              />
              <img
                src="/images/grid-image3.png"
                alt="Grid 3"
                className="slider-image"
              />
              <img
                src="/images/grid-image4.png"
                alt="Grid 4"
                className="slider-image"
              />
              <img
                src="/images/grid-image5.png"
                alt="Grid 5"
                className="slider-image"
              />
              <img
                src="/images/grid-image6.png"
                alt="Grid 6"
                className="slider-image"
              />
              <img
                src="/images/grid-image8.png"
                alt="Grid 6"
                className="slider-image"
              />
              <img
                src="/images/grid-image7.png"
                alt="Grid 6"
                className="slider-image"
              />
              <img
                src="/images/grid-image10.png"
                alt="Grid 6"
                className="slider-image"
              />
              <img
                src="/images/grid-image9.png"
                alt="Grid 6"
                className="slider-image"
              />
              <img
                src="/images/grid-image11.png"
                alt="Grid 6"
                className="slider-image"
              />
              <img
                src="/images/grid-image12.png"
                alt="Grid 6"
                className="slider-image"
              />
            </div>
          </div>
          <h2 className="section-heading">Popular Producsts</h2>
          <PopularProducts />
          <CompanyLogos />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
