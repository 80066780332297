// Footer.js
import React from "react";
import "./Footer.css"; // Import the CSS for the footer
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-grid">
        <div className="footer-item">
          <h3>About Us</h3>
          <p>Colorful Graphics</p>
        </div>
        <div className="footer-item">
          <h3>Email</h3>
          <ul>
            <li>
              <a href="Official@colorfulgraphic.com">
                Official@colorfulgraphic.com
              </a>
              <li>
                <a href="Orders@colorfulgraphic.com">
                  Orders@colorfulgraphic.com
                </a>
              </li>
            </li>
          </ul>
        </div>
        <div className="footer-item">
          <h3>Call</h3>
          <ul>
            <li>+911207966780</li>
            <li>+91 75359 23253</li>
          </ul>
        </div>

        <div className="footer-item">
          <h3>Follow Us</h3>
          <ul className="social-media">
            <li>
              <a href="index.html">
                <i className="fab fa-facebook-f"></i> Facebook
              </a>
            </li>
            <li>
              <a href="index.html">
                <i className="fab fa-twitter"></i> Twitter
              </a>
            </li>
            <li>
              <a href="index.html">
                <i className="fab fa-instagram"></i> Instagram
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-item">
          <h3>Newsletter</h3>
          <form>
            <input type="email" placeholder="Your email" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
