// CompanyLogos.js
import React from "react";
import "./CompanyLogos.css"; // Import the CSS for the company logos

const CompanyLogos = () => {
  // List of logos and company names
  const companies = [
    { name: "Company 1", logo: "images/clients/1.jpg" },
    { name: "Company 2", logo: "images/clients/2.jpg" },
    { name: "Company 3", logo: "images/clients/3.jpg" },

    { name: "Company 3", logo: "images/clients/4.jpg" },
    { name: "Company 3", logo: "images/clients/5.jpg" },
    { name: "Company 3", logo: "images/clients/6.jpg" },
    { name: "Company 3", logo: "images/clients/7.jpg" },
    { name: "Company 3", logo: "images/clients/8.jpg" },
    { name: "Company 3", logo: "images/clients/9.jpg" },
    { name: "Company 3", logo: "images/clients/10.jpg" },
    { name: "Company 3", logo: "images/clients/11.jpg" },
    { name: "Company 3", logo: "images/clients/12.jpg" },
    { name: "Company 3", logo: "images/clients/13.jpg" },
    { name: "Company 3", logo: "images/clients/14.jpg" },
    { name: "Company 3", logo: "images/clients/15.jpg" },
    { name: "Company 3", logo: "images/clients/16.jpg" },
    // Add more companies and logos here
  ];

  return (
    <section className="company-logos">
      <h1 className="section-heading">Our Partners</h1>
      <div className="logos-grid">
        {companies.map((company, index) => (
          <div key={index} className="logo-item">
            <img src={company.logo} alt={company.name} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default CompanyLogos;
