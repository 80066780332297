import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./PopularProducts.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const images = [
  // "/images/PopularProducts/1.jpg",
  "/images/PopularProducts/2.jpg",
  "/images/PopularProducts/3.png",
  "/images/PopularProducts/4.png",
  "/images/PopularProducts/5.png",
  "/images/PopularProducts/7.png",
  "/images/PopularProducts/8.png",
  "/images/PopularProducts/9.png",
  // "/images/PopularProducts/10.png",
  "/images/PopularProducts/11.png",
  // "/images/PopularProducts/12.jpg",
];

function PopularProducts() {
  return (
    <div className="carousel-container">
      <Carousel
        responsive={responsive}
        infinite={true}
        // autoPlay={true}
        autoPlaySpeed={3000}
        // showDots={true}
        arrow={true}
      >
        {images.map((image, index) => (
          <div key={index} className="carousel-item">
            <img src={image} alt={`Carousel ${index}`} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default PopularProducts;
